<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="editEvent">
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit"
        @keydown.enter="handleSubmit"
      >
        <b-row>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Nombre')"
              rules="required"
            >
              <b-form-group
                :label="$t('Nombre')"
                label-for="project-name"
              >
                <b-form-input
                  v-model="event.name"
                  name="name"
                  :placeholder="$t('Nombre')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Alias')"
              rules="required"
            >
              <b-form-group
                :label="$t('Alias')"
                label-for="project-reference"
              >
                <b-form-input
                  v-model="event.alias"
                  name="reference"
                  :placeholder="$t('Alias')"
                  readonly
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <b-form-group
                :label="$t('Responsable_roles')"
                label-for="account-responsable-roles"
            >
              <v-select
                  label="display_name"
                  :filterable="false"
                  :searchable="false"
                  :options="selectRoles"
                  multiple
                  v-model="responsable_roles"
                  :placeholder="$t('Roles')"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
                :label="$t('User_roles')"
                label-for="account-user-roles"
            >
              <v-select
                  label="display_name"
                  :filterable="false"
                  :searchable="false"
                  :options="selectRoles"
                  multiple
                  v-model="users_roles"
                  :placeholder="$t('Roles')"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12" class="pb-1">
            <b-form-checkbox
                v-model="event.show_task"

                plain
            >
              {{ $t('show_task') }}
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              :label="$t('Formulario genérico')"
              label-for="url_form"
            >
                <v-select
                  label="name"
                  :filterable="false"
                  :searchable="false"
                  :options="selectForms"
                  v-model="form_id"
                />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              :label="$t('URL formulario')"
              label-for="url_form"
            >
              <b-form-input
                v-model="event.url_form"
                name="name"
                :placeholder="$t('URL formulario')"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <validation-provider
              #default="{ errors }"
              :name="$t('Descripcion')"
              rules="required"
            >
              <b-form-group
                :label="$t('Descripcion')"
                label-for="project-description"
              >
                <quill-editor
                  v-model="event.description"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <b-button
              type="submit"
              variant="primary"
              class="mt-2 mr-1"
            >
              {{ $t('Actualizar') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BListGroupItem, BFormCheckbox,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import SelectCategories from "@/components/selectCategories/SelectCategories.vue";

export default {
  components: {
      SelectCategories,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    draggable,
    BListGroupItem,
    vSelect,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      trashZone: [],
      responsable_roles: [],
      users_roles: [],
      form_id: '',
    }
  },
  computed: {
    ...mapGetters({
      event: 'events/getEvent',
      selectRoles: 'users/getRoles',
      selectForms: 'forms/getItems',
      formSelected: 'forms/getForm',
    }),
  },
  methods: {
    ...mapActions({
      edit: 'events/edit',
      getEvent: 'events/getEvent',
      getSelectForms: 'forms/getList',
      getRoles: 'users/getRoles',
      getForm: 'forms/getForm',
    }),
    handleSubmit() {
      this.$refs.editEvent.validate().then(success => {
        if (success) {
          const { id } = this.event
          const formData = this.createFormData()
          if (formData) {
            this.edit({ id, event: formData })
          }
        }
      })
    },
    setResponsableRoles() {
      if (this.event.responsable_roles) {
        this.responsable_roles = this.event.responsable_roles
      }
    },
    setUsersRoles() {
      if (this.event.users_roles) {
        this.users_roles = this.event.users_roles
      }
    },
    createFormData() {
      const data = new FormData()
      if (this.responsable_roles.length > 0) {
        this.responsable_roles.forEach(element => {
          data.append('responsable_roles[]', element.id)
        })
      } else {
        data.append('responsable_roles', '')
      }
      if (this.users_roles.length > 0) {
        this.users_roles.forEach(element => {
          data.append('users_roles[]', element.id)
        })
      } else {
        data.append('users_roles', '')
      }
      data.append('url_form', this.event.url_form)
      data.append('show_task', this.event.show_task)
      data.append('name', this.event.name)
      data.append('alias', this.event.alias)
      data.append('description', this.event.description)
      const formId = (this.form_id) ? this.form_id.id : ''
      data.append('form_id', formId)

      return data
    },
  },
  async created() {
    await this.getEvent(this.$route.params.id)
    await this.getRoles()
    await this.setResponsableRoles()
    await this.setUsersRoles()
    await this.getSelectForms({
      page: 1, per_page: 99999, search: '', type: this.event.alias,
    })
    if (this.event.form_id) {
      await this.getForm(this.event.form_id)
      this.form_id = this.formSelected
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
